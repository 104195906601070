<template>
	<div class="ewm page-sub-box">
		<div class="locker_setting_list sub_new_style01 sub_ui_box1">
			<div class="page_search_box line_bottom_1px mb-0">
				<DxValidationGroup ref="searchValidationGroup">
					<div class="flex space-x-2 inner">
						<div class="mt-2">
							기준년월
						</div>
						<DxDateBox
							styling-mode="outlined"
							width="100"
							height="30"
							v-model="searchType.customTypes.frScheYmd"
							type="date"
							dateSerializationFormat="yyyyMMdd"
							display-format="yyyy.MM"
							dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
							:max="searchType.customTypes.toScheYmd"
							maxLength="7"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'decade',
								maxZoomLevel: 'year',
							}"
							@value-changed="onDayStartChanged"
						>
							<DxValidator>
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<div class="ui-datepicker period">
							<span class="dash">~</span>
						</div>
						<DxDateBox
							styling-mode="outlined"
							width="100"
							height="30"
							v-model="searchType.customTypes.toScheYmd"
							type="date"
							dateSerializationFormat="yyyyMMdd"
							display-format="yyyy.MM"
							dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
							:min="searchType.customTypes.frScheYmd"
							maxLength="7"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'decade',
								maxZoomLevel: 'year',
							}"
							@value-changed="onDayEndChanged"
						>
							<DxValidator>
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>

						<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
					</div>
				</DxValidationGroup>
			</div>
			<div class="cusmain-table-wrap">
				<DxDataGrid
					class="grid-box"
					ref="educationScheduleGrid"
					:data-source="dataGrid.dataSource"
					:selected-row-keys="dataGrid.selectedRowKeys"
					:allow-column-resizing="true"
					column-resizing-mode="widget"
					:show-borders="false"
					:show-column-lines="true"
					:show-row-lines="true"
					:remote-operations="true"
					:no-data-text="this.$_msgContents('CMN_NO_DATA')"
					height="calc(100vh - 370px)"
					@toolbar-preparing="onToolbarPreparing"
					@init-new-row="onInitNewRow"
					@row-click="onRowClick"
					@row-expanding="onRowExpanding"
					@saving="e => onSave(e)"
				>
					<DxPaging :enabled="false" />
					<DxSelection
						v-if="dataGrid.selecting"
						:mode="dataGrid.selecting.mode"
						:show-check-boxes-mode="dataGrid.selecting.showCheckBoxesMode"
						:select-all-mode="dataGrid.selecting.selectAllMode"
					/>
					<DxEditing
						:allow-updating="dataGrid.editing.allowUpdating"
						:allow-deleting="dataGrid.editing.allowDeleting"
						:allow-adding="dataGrid.editing.allowAdding"
						:mode="dataGrid.editing.mode"
						:select-text-on-edit-start="dataGrid.editing.selectTextOnEditStart"
						:start-edit-action="dataGrid.editing.startEditAction"
					/>
					<DxScrolling mode="standard" />
					<DxLoadPanel :enabled="true" />
					<DxFilterRow :visible="true" />
					<DxHeaderFilter :visible="true" />
					<DxGroupPanel :visible="false" />

					<DxColumn data-field="scheNm" caption="교육계획명" :allow-sorting="true">
						<DxRequiredRule message="교육계획명은 필수입니다." />
					</DxColumn>
					<DxColumn
						data-field="processCd"
						caption="진행상태"
						:width="170"
						alignment="center"
						cell-template="processCdTemplate"
						:allow-editing="false"
						:allow-filtering="true"
						:allow-header-filtering="true"
						:allow-sorting="true"
						:calculate-sort-value="sortByProcessCd"
					>
						<DxLookup :data-source="this.$_enums.ewm.eduEducationProcess.values" value-expr="value" display-expr="label" />
					</DxColumn>
					<DxColumn
						data-field="scheYmd"
						caption="기준년월"
						:width="130"
						alignment="center"
						:allow-header-filtering="false"
						:allow-filtering="true"
						:allow-sorting="true"
						data-type="date"
						format="yyyy-MM"
						:editor-options="{
							dateType: 'date',
							calendarOptions: {
								zoomLevel: 'year',
								minZoomLevel: 'decade',
								maxZoomLevel: 'year',
							},
						}"
					>
						<DxRequiredRule message="기준년월은 필수입니다." />
					</DxColumn>
					<DxColumn data-field="ymdStart" :width="130" alignment="center" data-type="date" format="yyyy-MM-dd" :visible="false">
						<DxRequiredRule message="교육시작일은 필수입니다." />
					</DxColumn>
					<DxColumn data-field="ymdEnd" :width="130" alignment="center" data-type="date" format="yyyy-MM-dd" :visible="false">
						<DxRequiredRule message="교육마감일은 필수입니다." />
						<DxCustomRule
							message="교육기간이 유효하지 않습니다."
							:ignore-empty-value="true"
							:validation-callback="
								options => {
									return options.data.ymdStart ? options.data.ymdEnd >= options.data.ymdStart : true;
								}
							"
						/>
					</DxColumn>
					<DxColumn
						caption="교육기간"
						data-field="ymdPeriod"
						:allow-sorting="true"
						:width="230"
						alignment="center"
						:allow-header-filtering="false"
						:allow-filtering="true"
						:calculate-cell-value="
							rowData => {
								return (rowData.ymdPeriod = `${rowData.ymdStart ? formatDate(rowData.ymdStart) : ''} ~ ${
									rowData.ymdEnd ? formatDate(rowData.ymdEnd) : ''
								}`);
							}
						"
						edit-cell-template="ymdDateTemplate"
					>
						<DxRequiredRule message="교육기간은 필수입니다." />
						<DxCustomRule
							message="교육기간이 유효하지 않습니다."
							:ignore-empty-value="true"
							:validation-callback="
								options => {
									return options.data.ymdStart ? options.data.ymdEnd >= options.data.ymdStart : true;
								}
							"
						/>
					</DxColumn>
					<DxColumn
						caption="피드백기간"
						data-field="feedbackPeriod"
						:width="230"
						alignment="center"
						:allow-header-filtering="false"
						:allow-sorting="true"
						:calculate-cell-value="
							rowData => {
								return (rowData.feedbackPeriod = `${
									rowData.feedbackYmdStart ? formatDate(rowData.feedbackYmdStart) : ''
								} ~ ${rowData.feedbackYmdEnd ? formatDate(rowData.feedbackYmdEnd) : ''}`);
							}
						"
						edit-cell-template="feedbackTemplate"
					>
						<DxRequiredRule message="피드백기간은 필수입니다." />
						<DxCustomRule
							message="피드백기간이 유효하지 않습니다."
							:ignore-empty-value="true"
							:validation-callback="
								options => {
									return (
										(options.data.ymdEnd ? options.data.feedbackYmdStart >= options.data.ymdEnd : true) &&
										(options.data.feedbackYmdStart
											? options.data.feedbackYmdEnd >= options.data.feedbackYmdStart
											: true)
									);
								}
							"
						/>
						<!-- <DxCustomRule
							message="피드백기간이 유효하지 않습니다."
							:ignore-empty-value="true"
							:validation-callback="options => {
								return options.data.feedbackYmdStart ? options.data.feedbackYmdEnd >= options.data.feedbackYmdStart : true;
							}"
						/> -->
					</DxColumn>
					<DxColumn
						caption="시작"
						data-field="feedbackYmdStart"
						:width="130"
						alignment="center"
						data-type="date"
						format="yyyy-MM-dd"
						cell-template="ymdTemplate"
						:visible="false"
					>
						<DxRequiredRule message="피드백시작기간은 필수입니다." />
						<DxCustomRule
							message="피드백기간은 교육기간 이후로 선택하세요."
							:ignore-empty-value="true"
							:validation-callback="
								options => {
									return options.data.ymdEnd ? options.data.feedbackYmdStart >= options.data.ymdEnd : true;
								}
							"
						/>
					</DxColumn>
					<DxColumn
						caption="마감"
						data-field="feedbackYmdEnd"
						:width="130"
						alignment="center"
						data-type="date"
						format="yyyy-MM-dd"
						cell-template="ymdTemplate"
						:visible="false"
					>
						<DxRequiredRule message="피드백마감기간은 필수입니다." />
						<DxCustomRule
							message="피드백기간이 유효하지 않습니다."
							:ignore-empty-value="true"
							:validation-callback="
								options => {
									return options.data.feedbackYmdStart
										? options.data.feedbackYmdEnd >= options.data.feedbackYmdStart
										: true;
								}
							"
						/>
					</DxColumn>
					<DxColumn
						data-field="eduTime"
						caption="총 교육시간"
						:allow-sorting="true"
						:width="100"
						alignment="center"
						:allow-header-filtering="false"
						:allow-filtering="false"
						:allow-editing="false"
						:calculate-display-value="calculateEduTime"
					/>
					<DxColumn
						data-field="id"
						caption="변경이력"
						:allow-sorting="false"
						alignment="center"
						:allow-header-filtering="false"
						:allow-filtering="false"
						:width="110"
						cell-template="processLogTemplate"
						:allow-editing="false"
					/>

					<DxMasterDetail :enabled="true" template="masterDetailTemplate" />

					<template #masterDetailTemplate="{ data: proc }">
						<div style="height: 330px; overflow: auto;" class="bg-white border rounded-md">
							<subject-grid-template :server="proc.key"></subject-grid-template>
						</div>
					</template>

					<template #processCdTemplate="{ data: data }">
						<div class="flex">
							<div class="flex-none">
								<DxButton
									type="button"
									:height="30"
									class="btn_XS white outlined mdi mdi-chevron-left"
									:disabled="checkProcess(1, data)"
									@click="onChangeProcess(data, 'PREV')"
								/>
							</div>
							<div class="flex-grow pt-2">
								{{ findProcess(data.value) }}
							</div>
							<div class="flex-none">
								<DxButton
									type="button"
									:height="30"
									class="btn_XS white outlined mr-0 mdi mdi-chevron-right"
									:disabled="checkProcess(2, data)"
									@click="onChangeProcess(data, 'NEXT')"
								/>
							</div>
						</div>
					</template>

					<template #scheYmdTemplate="{ data: data }">
						<DxDateBox
							:value="data.data.scheYmd"
							:styling-mode="config.pageSetting.stylingMode"
							:disabled="disabledProcess(data.data.processCd)"
							type="date"
							dateSerializationFormat="yyyy-MM-dd"
							display-format="yyyy.MM"
						/>
					</template>

					<template #ymdDateTemplate="{ data: data }">
						<div class="flex justify-center items-center z-50">
							<DxDateBox
								:value="data.data.ymdStart"
								:styling-mode="config.pageSetting.stylingMode"
								:disabled="disabledProcess(data.data.processCd)"
								:width="90"
								type="date"
								dateSerializationFormat="yyyy-MM-dd"
								display-format="yyyy.MM.dd"
								@value-changed="
									e => {
										//data.setValue(e.value);
										data.component.cellValue(data.rowIndex, 'ymdPeriod', `${e.value}~${data.data.ymdEnd}`);
										data.component.cellValue(data.rowIndex, 'ymdStart', e.value);
									}
								"
							>
								<DxValidator>
									<DxRequiredRule message="교육기간은 필수입니다." />
								</DxValidator>
							</DxDateBox>
							<div class="ml-3">~</div>
							<DxDateBox
								:value="data.data.ymdEnd"
								:styling-mode="config.pageSetting.stylingMode"
								:disabled="disabledProcess(data.data.processCd)"
								:width="90"
								type="date"
								dateSerializationFormat="yyyy-MM-dd"
								display-format="yyyy.MM.dd"
								@value-changed="
									e => {
										//data.setValue(e.value);
										data.component.cellValue(data.rowIndex, 'ymdPeriod', `${data.data.ymdStart}~${e.value}`);
										data.component.cellValue(data.rowIndex, 'ymdEnd', e.value);
									}
								"
							>
								<DxValidator>
									<DxRequiredRule message="교육기간은 필수입니다." />
								</DxValidator>
							</DxDateBox>
						</div>
					</template>

					<template #feedbackTemplate="{ data: data }">
						<div class="flex justify-center items-center z-50">
							<DxDateBox
								:value="data.data.feedbackYmdStart"
								:styling-mode="config.pageSetting.stylingMode"
								:disabled="disabledProcess(data.data.processCd)"
								:width="90"
								type="date"
								dateSerializationFormat="yyyy-MM-dd"
								display-format="yyyy.MM.dd"
								@value-changed="
									e => {
										//data.setValue(e.value);
										data.component.cellValue(data.rowIndex, 'feedbackPeriod', `${e.value}~${data.data.feedbackYmdEnd}`);
										data.component.cellValue(data.rowIndex, 'feedbackYmdStart', e.value);
									}
								"
							>
								<DxValidator>
									<DxRequiredRule message="피드백기간은 필수입니다." />
								</DxValidator>
							</DxDateBox>
							<div class="ml-3">~</div>
							<DxDateBox
								:value="data.data.feedbackYmdEnd"
								:styling-mode="config.pageSetting.stylingMode"
								:disabled="disabledProcess(data.data.processCd)"
								:width="90"
								type="date"
								dateSerializationFormat="yyyy-MM-dd"
								display-format="yyyy.MM.dd"
								@value-changed="
									e => {
										//data.setValue(e.value);
										data.component.cellValue(
											data.rowIndex,
											'feedbackPeriod',
											`${data.data.feedbackYmdStart}~${e.value}`,
										);
										data.component.cellValue(data.rowIndex, 'feedbackYmdEnd', e.value);
									}
								"
							>
								<DxValidator>
									<DxRequiredRule message="피드백기간은 필수입니다." />
								</DxValidator>
							</DxDateBox>
						</div>
					</template>

					<template #ymdTemplate="{ data: data }">
						<DxDateBox
							v-model="data.value"
							:styling-mode="config.pageSetting.stylingMode"
							:disabled="disabledProcess(data.data.processCd)"
							:width="110"
							type="date"
							dateSerializationFormat="yyyy-MM-dd"
							display-format="yyyy.MM.dd"
						>
						</DxDateBox>
					</template>

					<template #processLogTemplate="{ data: data }">
						<DxButton
							text="변경이력"
							class="btn_XS white light_filled mr-0"
							type="button"
							:width="80"
							:height="30"
							@click="showProcessLog(data)"
						/>
					</template>
					<!-- toolbar -->
					<template #totalCount>
						<div class="total-count-item">
							검색결과 <span class="tet-calr1">{{ totalCount }}</span> 개
						</div>
					</template>
				</DxDataGrid>
			</div>
		</div>

		<!-- Modal Layer -->
		<DxPopup
			:show-title="true"
			:title="modal.initData ? modal.initData.title : null"
			:min-width="modal.initData ? modal.initData.width : null"
			:width="modal.initData ? modal.initData.width : null"
			:min-height="modal.initData ? modal.initData.height : null"
			:height="modal.initData ? modal.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			v-model="modal.isOpened"
			:visible="modal.isOpened"
			@hiding="isOpenModal(false)"
		>
			<template #content>
				<div>
					<component :is="modal.currentComponent" :modalData="modal.sendData" v-model="modal.contentData"></component>
				</div>
			</template>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.hasOwnProperty('save')
							: false
						: false
				"
				:options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.text
							: ''
						: '',
					width: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.width
							: 120
						: 120,
					height: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.height
							: 40
						: 40,
					onClick: () => {
						onConfirmModal();
					},
				}"
			/>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.hasOwnProperty('cancel')
							: !modal.initData.buttons.hasOwnProperty('cancel')
						: false
				"
				:options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.text
							: ''
						: '',
					width: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.width
							: 120
						: 120,
					height: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.height
							: 40
						: 40,
					onClick: () => {
						isOpenModal(false);
					},
				}"
			/>
		</DxPopup>
		<!-- /Modal Layer -->
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import {
	DxDataGrid,
	DxColumn,
	DxEditing,
	DxMasterDetail,
	DxTexts,
	DxSelection,
	DxLookup,
	DxFilterRow,
	DxPaging,
	DxPager,
	DxOperationDescriptions,
	DxRemoteOperations,
	DxHeaderFilter,
	DxScrolling,
	DxSorting,
	DxColumnChooser,
	DxLoadPanel,
	DxGrouping,
	DxGroupPanel,
	DxRequiredRule,
	DxExport,
	DxRowDragging,
	DxPatternRule,
	DxCustomRule,
	DxToolbar,
	DxItem,
} from 'devextreme-vue/data-grid';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { getPastFromToday, isSuccess, setGridSingleSelection } from '@/plugins/common-lib';
import { DxValidator, DxRangeRule } from 'devextreme-vue/validator';
import { DxValidationGroup } from 'devextreme-vue/validation-group';

import SubjectGridTemplate from '@/pages/ewm/education/implementation/schedule/subject-grid.vue';
import ModalProcessStatus from '@/pages/ewm/education/implementation/schedule/modal-process-status-history.vue';

let vm = this;

/* const EDU_PROCESS = [
	{ processCd: 931, processNm: '대기'},
	{ processCd: 926, processNm: '교육진행'},
	{ processCd: 927, processNm: '교육완료'},
	{ processCd: 928, processNm: '피드백진행'},
	{ processCd: 929, processNm: '피드백완료'},
	{ processCd: 930, processNm: '교육마감'}
]; */

export default {
	components: {
		DxButton,
		DxDateBox,
		DxDataGrid,
		DxColumn,
		DxEditing,
		DxMasterDetail,
		DxTexts,
		DxSelection,
		DxLookup,
		DxFilterRow,
		DxPaging,
		DxPager,
		DxOperationDescriptions,
		DxRemoteOperations,
		DxHeaderFilter,
		DxScrolling,
		DxSorting,
		DxRequiredRule,
		DxColumnChooser,
		DxLoadPanel,
		DxGrouping,
		DxGroupPanel,
		DxExport,
		DxRowDragging,
		DxPatternRule,
		DxCustomRule,

		DxPopup,
		DxToolbarItem,
		DxToolbar,
		DxItem,
		DxValidator,
		DxValidationGroup,
		DxRangeRule,

		SubjectGridTemplate,
		ModalProcessStatus,
	},
	data() {
		return {
			config: {
				updateYn: true,
				pageSetting: {
					stylingMode: 'outlined', //outlined, underlined, filled
				},
			},
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					frScheYmd: getPastFromToday(11, 'months'),
					toScheYmd: getPastFromToday(0, 'days'),
				},
				paramsData: {},
			},
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {},
				contentData: null,
			},
			totalCount: 0,
			dataGrid: {
				dataSource: [],
				selectedRowsData: [],
				selectedRowKeys: [],
				rowOriginData: {},
				editing: {
					allowUpdating: options => {
						if (
							options.row.rowType == 'data' &&
							options.row.data.processCd !== this.$_enums.ewm.eduEducationProcess.READY.value
						) {
							return false;
						} else {
							return true;
						}
					},
					allowAdding: true,
					allowDeleting: false,
					mode: 'batch',
					startEditAction: 'click',
					selectTextOnEditStart: true,
				},
				selecting: {
					mode: 'multiple',
					selectAllMode: 'allPages',
					showCheckBoxesMode: 'always',
				},
			},
		};
	},
	computed: {
		searchValidationGroup: function() {
			return this.$refs['searchValidationGroup'].instance;
		},
		ewmCodes() {
			return this.$_enums.ewm;
		},
	},
	methods: {
		onRowClick(e) {
			// setGridSingleSelection(e);
			e.component.selectRowsByIndexes(e.dataIndex);
		},
		onRowExpanded(e) {
			//const s = e.component.getScrollable();
			/* const grid = this.$refs.educationScheduleGrid.instance;
			const s = grid.getScrollable();
			const rowIndex = grid.getRowIndexByKey(e.key);
			const rowEl = grid.getRowElement(rowIndex);
			console.log('rowEl', rowEl[0].offsetTop);
			console.log('s.scrollHeight()', s.scrollHeight()); */
			/* if (s.scrollHeight() < (rowIndex - 1) * rowEl[0].offsetHeight) {
				s.scrollTo((rowIndex - 1) * rowEl[0].offsetHeight);
			} */
			/* if (rowEl[0].offsetTop + 400 > s.scrollHeight()) {
				s.scrollTo(rowEl[0].offsetTop - rowEl[0].offsetHeight);
			} */
		},
		onRowExpanding(e) {
			//e.component.collapseAll(-1);
			this.collapseAll();
		},
		collapseAll() {
			this.$refs.educationScheduleGrid.instance.collapseAll(-1);
		},
		calculateEduTime(rowData) {
			const pad = num => num.toString().padStart(2, '0');
			return `${pad(Math.floor(rowData.eduTime / 60))}:${pad(rowData.eduTime % 60)}`;
		},
		/** @description : 소트설정 */
		sortByProcessCd(rowData) {
			return this.$_enums.ewm.eduEducationProcess.values.find(e => rowData.processCd == e.value).label;
		},
		/** @description: 그리드 상단 툴바 버튼 관련 이벤트 */
		onToolbarPreparing(e) {
			const toolbarItems = e.toolbarOptions.items;

			toolbarItems.forEach(item => {
				if (item.name === 'saveButton') {
					item.location = 'before';
					item.sortIndex = 40;
					item.options.icon = '';
					item.options.text = '저장';
					item.showText = 'always';
					item.options.elementAttr = { class: 'btn_XS default filled ' };
					item.options.width = '60';
					item.options.height = '30';
					item.options.visible = true;
				} else if (item.name === 'addRowButton') {
					item.location = 'before';
					item.sortIndex = 30;
					item.options.icon = '';
					item.options.text = '추가';
					item.showText = 'always';
					item.options.elementAttr = { class: 'btn_XS default filled add1' };
					item.options.width = '60';
					item.options.height = '30';
					item.options.visible = true;
				} else if (item.name === 'revertButton') {
					item.location = 'before';
					item.sortIndex = 50;
					item.options.icon = '';
					item.options.text = '취소';
					item.options.elementAttr = { class: 'btn_XS white light_filled ' };
					item.showText = 'always';
					item.options.width = '60';
					item.options.height = '30';
					item.options.visible = true;
				}
			});

			toolbarItems.push({
				widget: 'dxButton',
				options: {
					icon: '',
					text: '삭제',
					showText: 'always',
					elementAttr: { class: 'btn_XS white light_filled trash' },
					width: 60,
					height: 30,
					onClick: () => vm.onDeleteData(),
				},
				location: 'before',
				sortIndex: 60,
			});

			toolbarItems.unshift({
				location: 'after',
				widget: 'dxTemplate',
				template: 'totalCount',
			});
		},
		onInitNewRow(e) {
			e.data.processCd = this.$_enums.ewm.eduEducationProcess.READY.value;
			e.data.eduTime = 0;

			e.data.ymdStart = null;
			e.data.ymdEnd = null;

			e.data.feedbackYmdStart = null;
			e.data.feedbackYmdEnd = null;
		},
		/** @description : 저장 */
		async onSave(e) {
			// e.cancel = true;

			if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), { title: `<h2>알림</h2>` }))) {
				return;
			}

			if (!e.changes.length) {
				return;
			}

			let data = [];

			if (e.changes && e.changes.length > 0) {
				e.changes.forEach(d => {
					if (d.type === 'update') {
						let rowIndex = e.component.getRowIndexByKey(d.key);
						let row = e.component.getVisibleRows()[rowIndex];
						let updateData = row.data;

						data.push(updateData);
					} else if (d.type === 'insert') {
						if (Object.keys(d.data).length !== 0) {
							let updateData = { ...d.data };
							updateData['id'] = null;

							data.push(updateData);
						}
					}
				});
			}

			data.forEach(f => {
				f.scheYmd = this.formatDate(f.scheYmd);
				f.ymdStart = this.formatDate(f.ymdStart);
				f.ymdEnd = this.formatDate(f.ymdEnd);
				f.feedbackYmdStart = this.formatDate(f.feedbackYmdStart);
				f.feedbackYmdEnd = this.formatDate(f.feedbackYmdEnd);
			});

			const payload = {
				actionname: 'EWM_EDUCATION_SCHEDULE_SAVE',
				data: { data: data },
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				this.selectDataList();
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 삭제 */
		async onDeleteData() {
			const selectedRowKeys = this.$refs.educationScheduleGrid.instance.getSelectedRowKeys();

			if (!selectedRowKeys?.length) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			const checkEduProcessCd = selectedRowKeys.findIndex(
				element => element.processCd !== this.$_enums.ewm.eduEducationProcess.READY.value,
			);
			if (checkEduProcessCd > -1) {
				this.$_Msg(`진행상태가 [대기]인 데이터만 삭제 할 수 있습니다.`);
				return;
			}

			if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

			const sendData = selectedRowKeys.map(x => x.id);

			const payload = {
				actionname: 'EWM_EDUCATION_SCHEDULE_DELETE',
				data: sendData,
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
				this.selectDataList();
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		findProcess(value) {
			//return EDU_PROCESS.find(item => item.processCd === value).processNm;
			return this.$_enums.ewm.eduEducationProcess.values.find(e => value === e.value).label;
		},
		/** @description : 데이터 검색 메서드 */
		checkProcess(num, data) {
			if (num === 1) {
				/* if(EDU_PROCESS[0].processCd === data.value){
					return true;
				}else{
					return false;
				} */

				return this.$_enums.ewm.eduEducationProcess.READY.value === data.value;
			} else if (num === 2) {
				/* if(EDU_PROCESS[EDU_PROCESS.length-1].processCd === data.value){
					return true;
				}else{
					return false;
				} */

				return this.$_enums.ewm.eduEducationProcess.FINISH.value === data.value;
			}
		},
		disabledProcess(cd) {
			if (cd === this.$_enums.ewm.eduEducationProcess.READY.value) {
				return false;
			} else {
				return true;
			}
		},
		/** @description : 상태 변경 */
		async onChangeProcess(options, type) {
			if (options.data.id === undefined) {
				this.$_Toast(`저장 후 변경할 수 있습니다.`);
				return;
			}
			//const curProcessIdx = EDU_PROCESS.findIndex(element => element.processCd === options.row.data.processCd);
			const curProcessIdx = this.$_enums.ewm.eduEducationProcess.values.findIndex(
				element => element.value === options.row.data.processCd,
			);

			const chgProcessCdIdx = curProcessIdx + (type == 'PREV' ? -1 : 1);
			if (chgProcessCdIdx < 0 || chgProcessCdIdx >= this.$_enums.ewm.eduEducationProcess.values.length) {
				return;
			}

			if (type == 'PREV') {
				if (
					this.$_enums.ewm.eduEducationProcess.values[chgProcessCdIdx].value ===
						this.$_enums.ewm.eduEducationProcess.PROGRESS.value &&
					!(await this.$_Confirm('교육 데이터가 초기화됩니다. 변경하시겠습니까?'))
				) {
					return;
				}
				if (
					this.$_enums.ewm.eduEducationProcess.values[chgProcessCdIdx].value ===
						this.$_enums.ewm.eduEducationProcess.FEEDBACK.value &&
					!(await this.$_Confirm('등록한 피드백정보가 초기화됩니다. 변경하시겠습니까?'))
				) {
					return;
				}
			}

			const chgProcessCd = this.$_enums.ewm.eduEducationProcess.values[chgProcessCdIdx].value;

			const data = {
				id: options.row.data.id,
				processCd: chgProcessCd,
			};

			const payload = {
				actionname: 'EWM_EDUCATION_SCHEDULE_CHANGE_PROCESS',
				data: data,
			};

			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.$set(options.row.data, 'processCd', chgProcessCd);
				options.component.repaintRows(options.rowIndex);

				this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		onOpenModal(componentNm, componentInitData, propsData) {
			this.modal.currentComponent = componentNm;
			this.modal.initData = componentInitData;
			this.modal.sendData = propsData;
			this.isOpenModal(true);
		},
		/** @description: 팝업 오픈 체크 메서드 */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if (!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
			}
		},
		/** @description: 변경이력 모달 이벤트 */
		showProcessLog(data) {
			this.collapseAll();
			this.onOpenModal(
				'ModalProcessStatus',
				{
					title: '진행상태 변경이력',
					buttons: {
						cancel: { text: '닫기' },
					},
					width: '700',
					height: '520',
				},
				{
					scheId: data.row.data.id,
				},
			);
		},
		/** @description : 데이터 검색 메서드 */
		async selectDataList() {
			if (!vm.searchValidationGroup.validate().isValid) {
				return;
			}

			this.$refs.educationScheduleGrid.instance.collapseAll(-1);

			vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.frScheYmd;
			vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.toScheYmd;
			//let params = { ...params, ...vm.searchType.paramsData };
			let params = { ...vm.searchType.paramsData };

			const payload = {
				actionname: 'EWM_EDUCATION_SCHEDULE_LIST',
				data: params,
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.totalCount = res.data.data.length;
				this.dataGrid.dataSource = res.data.data;
			} else {
				return () => {
					throw 'Data Loading Error';
				};
			}
		},
		/** @description : 날짜 검색 */
		onChangeSearchDay() {
			const regDt = this.searchType.customTypes.frScheYmd + '000000' + '~' + this.searchType.customTypes.toScheYmd + '235959';
			this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

			this.$_setSearchHistsCustomType(this.searchType.paramsData);
		},
		/** @description : 날짜 형식 변환 메서드 */
		formatDate(date) {
			return this.$_commonlib.formatDate(date, 'YYYYMMDD', 'YYYY-MM-DD');
		},
		onDayStartChanged(e){
			const changed = e.value.slice(0, 6)+'01';
			this.searchType.customTypes.frScheYmd = changed;
		},
		onDayEndChanged(e){
			const changed = e.value.slice(0, 6)+'29';
			this.searchType.customTypes.toScheYmd = changed;
		},
		/** @description : 셀렉트 박스 초기화 */
		async initCodeMap() {
			//this.codeMap = await this.$_getCodeMapList('root_ewm_edu_education_process');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			vm = this;

		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {
			this.selectDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>

<style scoped>
.page_search_box .inner div {
	display: inline-block;
}
.page_search_box .inner > div:not(.box-btn-search) {
	vertical-align: middle;
	margin-right: 10px;
}
.page_search_box .spec-type-box {
	display: inline-block;
}
.page_search_box .inner .box-btn-search {
	margin-right: 15px;
}
.page_search_box #searchTooltip {
	margin-right: 5px;
	font-size: 22px;
	color: #808080;
}
</style>
